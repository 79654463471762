import { transformChatroom } from '@wix/wix-chat-transform-bo-events';
import * as generateToChat from './generateToChat';

const onMessageReceived = callback => {
  generateToChat.callbackEventWithTransformMessage(
    'ChatWidget.onMessageReceived',
    callback,
    'BUSINESS_TO_CUSTOMER',
  );
};

const onMessageSent = callback => {
  generateToChat.callbackEventWithTransformMessage(
    'ChatWidget.onMessageSent',
    callback,
    'CUSTOMER_TO_BUSINESS',
  );
  return;
};

const onMinimize = callback =>
  generateToChat.callbackEvent(
    'ChatWidget.onWidgetCollapsed',
    callback,
    'Collapsed',
  );

const onMaximize = callback =>
  generateToChat.callbackEvent('ChatWidget.onWidgetExpand', callback, 'Expand');

const sendMessage = ({ messageText, channelId = null }) =>
  generateToChat.request('ChatWidget.sendMessage', {
    message: messageText,
    chatroom: channelId,
  });

const maximize = () => generateToChat.request('ChatWidget.expandWidget', {});

const minimize = () => generateToChat.request('ChatWidget.collapseWidget', {});

const focusChannel = ({ channelId = '' } = {}) =>
  generateToChat.request('ChatWidget.focusChannel', { channelId });

const getChannelList = async () => {
  const listOfServerChatroomsDTO = await generateToChat.requestWithResult(
    'ChatWidget.getChatState',
    {},
    'chatrooms',
  );
  const listOfApiChatroomsDTO = listOfServerChatroomsDTO.map(chatroom =>
    transformChatroom(chatroom._chatroom),
  );
  return listOfApiChatroomsDTO;
};

const maximized = () =>
  generateToChat.requestWithResult(
    'ChatWidget.getChatState',
    {},
    'isWidgetExpanded',
  );

const getChannel = async ({ type = '', channelId = '' } = {}) => {
  const serverChatroomDTO: any = await generateToChat.request(
    'ChatWidget.getChannel',
    { type, channelId },
  );
  const apiChatroomDTO = transformChatroom(serverChatroomDTO.channel);
  return apiChatroomDTO;
};

const startChannel = ({ type = '', userId = '' } = {}) =>
  generateToChat.requestWithResult(
    'ChatWidget.startChannel',
    {
      type,
      userId,
    },
    'channelId',
  );

export const chatApiPublic = {
  onMessageReceived,
  onMessageSent,
  onMinimize,
  onMaximize,
  sendMessage,
  maximize,
  minimize,
  focusChannel,
  getChannelList,
  maximized,
  getChannel,
  startChannel,
};
